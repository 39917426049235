* {
  color: #fff;
  z-index: 1;
  background: #0e0a0f;
  font-family: panfortecondensed_light, Arial, sans-serif;
  text-decoration: none;
  transition: all 1s;
}

#sidebar {
  z-index: 3;
  background: #1d1222;
  width: 250px;
  height: 100%;
  transition: all .5s;
  position: fixed;
  top: 0;
  left: -360px;
}

#sidebar * {
  margin: 0;
  padding: 0;
}

#sidebar header {
  color: #fff;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  background: #1d1222;
  border-bottom: 1px solid #000;
  font-size: 22px;
  line-height: 85px;
  position: relative;
  top: -2.4rem;
}

#sidebar ul a {
  color: #fff;
  box-sizing: border-box;
  background: #1d1222;
  border-top: 1px solid #ffffff1a;
  border-bottom: 1px solid #000;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  font-size: 20px;
  line-height: 65px;
  transition: all .5s;
  display: block;
  position: relative;
}

.icon {
  background: #1d1222;
  max-width: 30px;
  max-height: 30px;
  position: relative;
  top: 8px;
  right: 8px;
}

#sidebar ul li:hover a {
  padding-left: 50px;
}

#sidebar ul a i {
  margin-right: 16px;
}

#sidebar ul li a {
  top: -2.4rem;
}

#sidebar ul li {
  background: #1d1222;
}

#navMenu > span {
  background-color: #fff;
  border-radius: 9999px;
  width: 28px;
  height: 2px;
  display: block;
}

#navMenu > span:not(:last-child) {
  margin-bottom: 7px;
}

#navMenu, #navMenu > span {
  transition: all .4s ease-in-out;
}

#navMenu {
  background: none;
}

#navMenu.active {
  background: #1d1222;
  transition-delay: .8s;
  transform: rotate(45deg);
}

#navMenu.active > span:nth-child(2) {
  width: 0;
}

#navMenu.active > span:first-child, #navMenu.active > span:nth-child(3) {
  transition-delay: .4s;
}

#navMenu.active > span:first-child {
  transform: translateY(9px);
}

#navMenu.active > span:nth-child(3) {
  transform: translateY(-9px)rotate(90deg);
}

#btn {
  cursor: pointer;
  z-index: 9999;
  background: none;
  border-radius: 3px;
  padding: 6px 12px;
  font-size: 35px;
  transition: all .5s;
  display: inline-block;
  position: relative;
  top: 25px;
  left: 375px;
}

#btn.active {
  left: 185px;
}

#sidebar.active {
  left: 0;
}

#particles-js {
  z-index: 0;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vmax !important;
}

.container {
  flex-direction: column;
  justify-content: center;
  display: flex;
}

#header_img {
  background-position: center;
  border-radius: 50%;
  width: 200px;
  height: 200px;
  margin: auto;
  transition: none;
  display: block;
  position: relative;
}

#name_display * {
  background: none;
}

#name_display {
  text-align: right;
  z-index: 2;
  background: none;
  font-size: 1.2rem;
  transition: none;
  position: relative;
  top: 0;
  right: 3rem;
}

.links {
  border: 3px solid #fff;
  justify-content: space-around;
  align-items: center;
  width: 60%;
  min-width: 250px;
  max-width: 375px;
  margin: auto;
  display: flex;
  position: relative;
}

.link_img {
  max-height: 75px;
}

.writer_space {
  background: none;
  justify-content: center;
  align-items: center;
  height: 15vh;
  display: flex;
}

.typewriter {
  white-space: nowrap;
  color: #fff;
  background: none;
  border-right: 3px solid;
  width: 22ch;
  font-family: monospace;
  font-size: 1.4em;
  animation: 2s steps(22, end) typing, .5s step-end infinite alternate blink;
  overflow: hidden;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: #0000;
  }
}

@media screen and (width >= 601px) {
  #header_img {
    width: 275px;
    height: 275px;
  }

  #name_display {
    font-size: 1.8rem;
  }

  .link_img {
    max-height: 85px;
  }

  .typewriter {
    font-size: 1.75em;
  }
}

@media screen and (width >= 1025px) {
  #header_img {
    width: 325px;
    height: 325px;
  }

  #name_display {
    font-size: 2.2rem;
  }

  .link_img {
    max-height: 100px;
  }

  .typewriter {
    font-size: 2em;
  }
}

/*# sourceMappingURL=index.f37dc2ea.css.map */
