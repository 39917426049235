/** Universal css for site */
@import url("./universal.css");

/** Sidebar css styles */
@import url("./sidebar.css");

/** Particles background styles */
@import url("./particles.css");

/** Mobile */
.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#header_img {
  background-position: center;
  border-radius: 50%;
  position: relative;
  display: block;
  margin: auto;
  height: 200px;
  width: 200px;
  transition: none;
}

#name_display * {
  background: transparent;
}

#name_display {
  text-align: right;
  position: relative;
  z-index: 2;
  background: transparent;
  right: 3rem;
  top: 0;
  font-size: 1.2rem;
  transition: none;
}

.links {
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  border-color: white;
  border-style: solid;
  border-width: 3px;
  min-width: 250px;
  width: 60%;
  max-width: 375px;
}

.link_img {
  max-height: 75px;
}

/** Type writer effect styles */
.writer_space {
  height: 15vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.typewriter {
  width: 22ch;
  animation: typing 2s steps(22), blink 0.5s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-family: monospace;
  color: white;
  background: transparent;
  font-size: 1.4em;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

/** Tablet */
@media screen and (min-width: 601px) {
  #header_img {
    height: 275px;
    width: 275px;
  }

  #name_display {
    font-size: 1.8rem;
  }

  .link_img {
    max-height: 85px;
  }

  .typewriter {
    font-size: 1.75em;
  }
}

/** Desktop */
@media screen and (min-width: 1025px) {
  #header_img {
    height: 325px;
    width: 325px;
  }

  #name_display {
    font-size: 2.2rem;
  }

  .link_img {
    max-height: 100px;
  }

  .typewriter {
    font-size: 2em;
  }
}
