/** Styles for the sidebar */
#sidebar {
  position: fixed;
  left: -360px;
  width: 250px;
  height: 100%;
  background: #1d1222;
  transition: all 0.5s ease;
  z-index: 3;
  top: 0;
}

#sidebar * {
  margin: 0;
  padding: 0;
}

#sidebar header {
  top: -2.4rem;
  font-size: 22px;
  color: white;
  text-align: center;
  line-height: 85px;
  user-select: none;
  border-bottom: 1px solid black;
  background: #1d1222;
  position: relative;
}

#sidebar ul a {
  display: block;
  height: 100%;
  width: 100%;
  line-height: 65px;
  font-size: 20px;
  color: white;
  padding-left: 40px;
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid black;
  transition: all 0.5s ease;
  background: #1d1222;
  position: relative;
}

.icon {
  max-height: 30px;
  max-width: 30px;
  top: 8px;
  right: 8px;
  position: relative;
  background: #1d1222;
}

#sidebar ul li:hover a {
  padding-left: 50px;
}

#sidebar ul a i {
  margin-right: 16px;
}

#sidebar ul li a {
  top: -2.4rem;
}

#sidebar ul li {
  background: #1d1222;
}

#navMenu > span {
  display: block;
  width: 28px;
  height: 2px;
  border-radius: 9999px;
  background-color: white;
}

#navMenu > span:not(:last-child) {
  margin-bottom: 7px;
}

#navMenu,
#navMenu > span {
  transition: all 0.4s ease-in-out;
}

#navMenu {
  background: transparent;
}

#navMenu.active {
  transition-delay: 0.8s;
  transform: rotate(45deg);
  background: #1d1222;
}

#navMenu.active > span:nth-child(2) {
  width: 0;
}

#navMenu.active > span:nth-child(1),
#navMenu.active > span:nth-child(3) {
  transition-delay: 0.4s;
}

#navMenu.active > span:nth-child(1) {
  transform: translateY(9px);
}

#navMenu.active > span:nth-child(3) {
  transform: translateY(-9px) rotate(90deg);
}

#btn {
  background: transparent;
  cursor: pointer;
  left: 375px;
  top: 25px;
  border-radius: 3px;
  padding: 5px;
  font-size: 35px;
  padding: 6px 12px;
  transition: all 0.5s;
  display: inline-block;
  position: relative;
  z-index: 9999;
}

#btn.active {
  left: 185px;
}

#sidebar.active {
  left: 0px;
}
